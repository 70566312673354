@charset "UTF-8";
/*
    Theme Name: WerkStatt Child Theme
    Theme URI: https://themeforest.net/user/fuelthemes
    Description: <strong>A Premium theme for magazine, editor, community and more! WordPress Theme by <a href="https://www.themeforest.net/user/fuelthemes">fuelthemes</a></strong> – Update notifications available on Twitter and Themeforest <a href="https://twitter.com/anteksiler">Follow us on twitter</a> – <a href="https://themeforest.net/user/fuelthemes">Follow us on Themeforest</a>
    Version: 1.0.0
    Author: fuelthemes
    Template: werkstatt
*/
/*background image has to be changed to the absolute path in the future website domain*/
@import url("/wp-content/themes/werkstatt/style.css");
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeIn-fromLeft {
	0% {
		opacity: 0;
		transform: translateX(-500px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeIn-fromLeft {
	0% {
		opacity: 0;
		transform: translateX(-500px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes fadeOut-fromLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-500px);
	}
}

@keyframes fadeOut-fromLeft {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-500px);
	}
}

@-webkit-keyframes fadeIn-fromRight {
	0% {
		opacity: 0;
		transform: translateX(500px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeIn-fromRight {
	0% {
		opacity: 0;
		transform: translateX(500px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes fadeOut-fromRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(500px);
	}
}

@keyframes fadeOut-fromRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(500px);
	}
}

/* BEGIN TEMPORARY: Hide brochure download button  */
#download-brochure > .columns > .vc_column-inner > .wpb_wrapper > .row:last-child {
	margin-bottom: 0 !important;
}

/*#download-brochure > .columns > .vc_column-inner > .wpb_wrapper > .row:first-child {
   display: none !important;
}*/
/* END TEMPORARY: Hide brochure download button  */
.main-page-header h1,
.building-bg-image h1,
.section#block-f h1 span,
ul#menu li[data-menuanchor="sec2"] {
	-webkit-animation: fadeIn 1500ms 750ms both;
	animation: fadeIn 1500ms 750ms both;
}

.main-page-header hr,
.building-bg-image hr,
.section#block-f h1 .white-bar2 {
	-webkit-animation: fadeIn-fromLeft 1500ms 750ms both;
	animation: fadeIn-fromLeft 1500ms 750ms both;
}

#cookie-notice {
	min-width: initial;
	background: rgba(255, 255, 255, 0.8) !important;
}

#cookie-notice .cookie-notice-container {
	text-align: left;
}

#cookie-notice #cn-notice-text {
	white-space: pre-wrap;
}

#cookie-notice .cn-button {
	background: #00aca0;
	border: 0 none;
	border-radius: initial;
	opacity: 1;
	color: #FFF;
	text-shadow: none;
}

#cookie-notice .cn-button:hover {
	opacity: 0.7;
}

.textwidget p a {
	display: inline-block;
	height: auto;
	width: auto;
	color: #fff;
	margin-bottom: 2px;
	border-bottom: 1px solid #00a199;
}

.copyright-desktop p a {
	margin: -3px 3px 0px 3px;
	font-size: 15px;
}

.persian-green button,
.persian-green a {
	background-color: #00A198;
}

.persian-green button:hover,
.persian-green a:hover {
	opacity: 0.7;
}

.footer-logo {
	display: inline !important;
	height: auto;
	width: auto;
}

#scroll_to_top {
	display: none;
}

.far, .fas {
	display: none;
}

.entry-title .ui-link {
	display: none !important;
}

/*--------------------------------------------------------*/
/* CUSTOM NAVBAR STYLYING */
.header:before {
	height: 0%;
}

.active {
	position: fixed;
}

header.header.style1.menu_style1 {
	padding: 0px;
}

.header {
	position: absolute;
}

#navbar {
	position: relative;
	width: 100%;
	position: fixed;
	top: 0;
	transition: top 0.2s ease-in-out;
	display: block;
	transition: top 0.3s;
	height: 95px;
	padding-left: 30px;
	padding-right: 30px;
	transition-timing-function: ease-in-out;
	-webkit-transition: all 0.5s;
	/* Safari */
	transition: all 0.5s;
}

#navbar a {
	float: left;
	display: block;
	color: white !important;
	text-align: center;
	padding: 15px;
	text-decoration: none;
	font-size: 18px;
}

#navbar a:hover {
	opacity: 0.7;
}

#navbar.scrolling-up .same-line:hover p,
#navbar.scrolling-up .mobile-toggle span {
	-webkit-transition: all 0.5s;
	/* Safari */
	transition: all 0.5s;
}

#navbar.scrolling-up .same-line:hover p {
	opacity: 1;
	color: #00a198 !important;
}

#navbar.scrolling-up .mobile-toggle:hover span {
	background-color: #00a198 !important;
}

.page-template-default .header.hover #navbar {
	background-color: rgba(18, 36, 67, 0.7);
}

.link-right {
	float: right;
	padding-top: 20px;
}

/* this disables the hover function that trigger the color change on style 1 mobile toggle */
.mobile-toggle {
	color: #00a198 !important;
}

.mobile-toggle span {
	background: white !important;
}

.mobile-toggle > div {
	width: 50px;
	height: 28px;
}

.header .logolink .logoimg.logo-light {
	opacity: 1;
	position: relative;
	display: block;
}

.logo-dark {
	display: none;
}

/*--------------------------------------------------------*/
/* FIXING ISSUES ON FOOTER, APART FROM HOME PAGE */
.page-id-2484 .ui-loader,
.page-id-2574 .ui-loader,
.page-id-2626 .ui-loader,
.page-id-2221 .ui-loader,
.page-id-560 .ui-loader,
.page-id-860 .ui-loader,
.page-id-1129 .ui-loader,
.page-id-965 .ui-loader,
.page-id-995 .ui-loader,
.page-id-218 .ui-loader,
.page-id-1524 .ui-loader,
.page-id-1650 .ui-loader,
.page-id-1633 .ui-loader {
	display: none !important;
}

/*--------------------------------------------------------*/
.the-story-header-wrapper hr, .header-txt-block-column hr, .building-header-wrapper hr {
	width: 135px;
	height: 1px;
	background: white;
	float: left;
	margin-top: 50px;
}

.page-id-501 p {
	color: white;
}

.viewport-height {
	height: 100vh !important;
}

.row {
	margin: 0 auto !important;
}

h1 {
	color: white;
	font-family: 'Roboto', 'Helvetica', sans-serif;
}

#img-fixed {
	position: fixed;
}

.hidden-pointer {
	display: none !important;
}

.div-name {
	color: white;
}

.body {
	font-family: Verdana;
}

#logo-holder-div {
	margin: 0;
	text-align: left;
}

.same-line {
	display: inline;
}

#mobile-menu-logo {
	max-width: 170px;
	padding-top: 17px;
}

.fp-tooltip {
	display: none;
}

.social-img {
	display: none !important;
	text-align: center;
}

#call-to-action-row {
	height: 200px !important;
	background-color: #f9c3b5;
	text-align: center;
	vertical-align: middle;
	line-height: 190px;
}

.call-to-action-buttom {
	border: solid 2px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 50px;
	padding-left: 50px;
}

.vc_custom_1536932257948 {
	background-repeat: no-repeat !important;
}

.vc_custom_1536659944282 {
	background-size: cover;
}

/* MENU FIXES */
#mobile-menu {
	background-color: #FFF !important;
}

#mobile-menu .menubg-placeholder {
	position: relative;
	top: 0px;
	left: 0;
	width: 100%;
	height: 0%;
	z-index: -1;
}

.thb-mobile-menu a {
	color: #009f98;
	font-weight: 400;
}

.thb-mobile-menu a:hover {
	color: #122146 !important;
}

.slick-gallery-nav .slick-slide {
	cursor: pointer;
}

@media only screen and (min-width: 90.0625em) {
	.thb-mobile-menu a {
		font-size: 45px;
	}
}

.menubg-placeholder {
	background: none !important;
}

#mobile-menu .thb-close path {
	stroke: #009f98;
}

#menu-navigation li {
	display: inline !important;
}

#mobile-menu .thb-close {
	top: 20px;
	right: 30px;
}

.custom_scroll {
	overflow: visible;
}

.thb-mobile-menu .link_container {
	padding-right: 0px;
}

.link_container {
	overflow: visible !important;
}

.thb-mobile-menu .menu_arrow {
	fill: #009f98;
	cursor: pointer;
}

/*____________________________________________-*/
/*to be applied on pages other than home page*/
hr.white-line {
	width: 115px;
	height: 1px;
	background: #ffffff;
	float: none;
	margin: 40px auto !important;
}

.mobile-toggle {
	color: white;
	line-height: 51px;
	height: 81px !important;
}

.mobile-toggle span {
	background: white;
	display: block;
	position: absolute;
	width: 31px;
	height: 3px;
	background: #1f1f1f;
	content: " ";
	right: 0;
	border-radius: 2px;
}

.mobile-toggle span:nth-child(2) {
	top: 8px;
}

.mobile-toggle span:nth-child(3) {
	top: 16px;
}

.hover .mobile-toggle span {
	background: white !important;
}

.hover {
	color: #009f98;
}

.hover .mobile-toggle {
	color: #009f98 !important;
}

a {
	text-decoration: none !important;
}

.the-story-row5-colum {
	min-height: 350px;
}

.page-id-560 .vc_column-inner {
	background-size: cover;
}

.page-id-560 .btn-lg {
	height: 44px !important;
	border: none !important;
	width: 50% !important;
	float: none !important;
	text-transform: none !important;
	font-size: 15px !important;
	margin: 0 auto;
	font-size: 20px !important;
}

#btn1 {
	background-color: #009f98 !important;
	outline: 2px solid #009f98 !important;
	outline-offset: 20px !important;
}

#btn2 {
	background-color: #f28e77 !important;
	outline: 2px solid #f28e77 !important;
	outline-offset: 20px !important;
}

.page-id-560 .btn-lg:hover {
	opacity: 0.8;
}

#the-story-row4 {
	min-height: 60vh !important;
}

.the-story-header-title {
	color: white;
	text-transform: none;
	font-size: 68px !important;
	line-height: 80px;
}

.block-page-content h5 {
	font-family: "Roboto", sans-serif !important;
	color: #122146;
	font-weight: 300;
	font-size: 16px;
	text-align: center;
}

.block-page-content h3 {
	font-family: "Cera Stencil Light", sans-serif !important;
	color: #00a198 !important;
	font-size: 50px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
}

.block-page-content p,
.block-page-content-white p {
	font-family: "Roboto", sans-serif !important;
	max-width: 550px;
	color: #122146;
	font-weight: 300;
	font-size: 18px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

.block-page-content p:last-child {
	margin-bottom: 0 !important;
}

.block-page-content-white h3 {
	font-family: "Cera Stencil Light", sans-serif !important;
	color: white;
	font-size: 50px !important;
	font-weight: 100;
}

.block-page-content-white h5 {
	font-family: "Roboto", sans-serif !important;
	color: white;
	font-weight: 300;
	font-size: 25px !important;
}

.block-page-content hr {
	background: #122146;
	width: 100px;
	display: block;
	height: 1px;
	float: none;
	margin: 40px auto;
}

.block-page-content-white p {
	color: white;
}

.floorplan-inner-row {
	max-width: 112.5rem !important;
	padding: 0px 140px !important;
}

.block-page-content {
	padding: 0px 0px;
	color: #122146;
}

.main-page-header {
	background-size: cover !important;
	background-position: bottom;
	position: relative;
	padding-left: 100px !important;
	padding-right: 100px !important;
}

.building-bg-image {
	align-items: center !important;
	padding-left: 115px !important;
	padding-right: 115px !important;
}

.building-title h1 {
	color: #fff;
	font-size: 68px;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	display: block;
}

/* Bottom Section Link Styles */
#green-border {
	border: 11px solid #00a198;
	background-color: rgba(0, 155, 146, 0.6);
}

#yellow-border {
	border: 11px solid #ffeda0;
	background-color: rgba(255, 231, 154, 0.6);
}

#peach-border {
	border: 11px solid #F5BBA9;
	background-color: rgba(245, 187, 169, 0.6);
}

#woman-row {
	margin-top: -36px;
}

.footer-h {
	text-align: center;
	font-weight: 400;
	color: #00a198 !important;
	font-family: "Cera Stencil Light", sans-serif !important;
	font-size: 29px;
	margin-bottom: 20px;
}

a.terms {
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid #00a199;
	padding-bottom: 0px;
	margin: 0px 5px;
}

.footer-list {
	color: white;
	text-align: left;
}

.social-img a {
	margin: 0px 4px;
}

.copyright-text {
	color: white;
	font-size: 14px;
}

.footer {
	background: url(/wp-content/themes/werkstatt-child/img/the-campus-footer-bg.jpg) !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	padding: 30px 0px;
}

#mobile-menu .thb-close svg {
	width: 50px;
	height: 50px;
}

#the-people-row1 {
	background-repeat: no-repeat;
	background-size: cover;
}

#the-people-row2 {
	background-position: bottom;
	padding-top: 100px;
	padding-bottom: 70px;
}

#the-people-row1-txt-block {
	color: white;
}

#the-people-row1-txt-block h1 {
	font-weight: 400;
	font-size: 45px;
}

#the-people-header-img {
	background-position: center;
	position: relative;
}

#testimonials-row h1 {
	font-family: "Cera Stencil Light", sans-serif !important;
	color: #ffeda0;
}

#testimonials-row .testimonial-text {
	color: white;
	max-width: 340px;
	padding: 0px 0px;
	position: relative;
	margin: 20px auto;
}

#testimonials-row .testimonial-signature {
	color: #ffeda0;
}

#counter-1, #counter-2, #counter-3, #counter-4, #counter-5 {
	width: 250px !important;
}

.title-wording {
	font-size: 80px;
}

.hr-white {
	background: white !important;
	border: none !important;
}

#menu-navigation li#menu-item-3061,
#menu-navigation li#menu-item-2897,
#menu-navigation li#menu-item-2896 {
	display: none !important;
}

#gallery .vc_images_carousel {
	width: 100% !important;
	height: 480px;
	margin: 0 auto;
}

#gallery .vc_images_carousel div {
	width: 100%;
	height: 100%;
}

#gallery .vc_images_carousel img {
	/* min-height: 480px; */
	width: 100%;
	height: auto;
	margin-top: 25%;
	transform: translateY(-50%);
}

#gallery .vc_images_carousel .vc_carousel-control {
	top: 0;
	margin-top: 0;
	height: 100%;
}

#gallery .vc_images_carousel .vc_carousel-control > span {
	width: 60px;
	height: 60px;
	margin-top: -30px;
	margin-left: -30px;
	font-size: 60px;
	color: #FFF;
}

#gallery .vc_images_carousel .vc_carousel-control > span:before {
	top: -15px;
}

#download-brochure {
	text-align: center;
}

#download-brochure .btn {
	min-width: 560px;
	height: auto;
	margin: 0 auto;
	background-color: white;
	font-family: "Cera Stencil Light", sans-serif !important;
	font-size: 39px;
	font-weight: 100;
	color: #FFF;
}

#download-brochure .btn.bordered {
	border: 1px solid #FFF;
}

#download-brochure .btn.yellow {
	border-color: #FFEDA0;
	color: #00a198;
}

#download-brochure .btn span {
	margin: 0 auto;
}

#download-brochure .btn:after {
	border-bottom: none;
}

#download-brochure .btn:hover {
	opacity: 0.9 !important;
}

.scroll-down {
	position: absolute;
	bottom: 10px;
	text-align: center;
	left: 0px;
	margin: 0px auto;
	right: 0px;
}

.the-story-header-title strong {
	font-weight: 600;
	letter-spacing: normal;
}

.footer .widget ul li {
	padding: 5px 0px;
}

.footer .widget ul li a {
	display: inline-flex;
	font-size: 15px;
	align-items: center;
	width: 130px;
	font-weight: 300;
	height: auto !important;
	color: #fff;
	vertical-align: middle;
	white-space: nowrap;
}

#ul1 li a {
	width: 100px;
}

#ul2 li a {
	width: 160px;
}

.scroll-down p {
	font-size: 15px;
	position: absolute;
	float: none;
	font-family: "Roboto", sans-serif !important;
	left: 0px;
	font-weight: 300;
	right: 0px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	bottom: 25px;
	margin-bottom: 0px;
}

.scroll-down img {
	max-width: 25px;
}

.more-character .block-page-content p {
	font-family: "Roboto", sans-serif !important;
	color: #122146;
	font-weight: 300;
	font-size: 18px;
	max-width: 550px;
	margin: 0 auto 0;
}

.p-justify p {
	text-align: justify;
}

.grecaptcha-badge {
	visibility: hidden;
}

/*--------------------------------------------------------*/
/*THE BUILDINGS PAGE STYLES*/
img.attachment-full.size-full.thb-lazyload.lazyautosizes.lazyloaded {
	margin: 0px auto !important;
	display: block;
}

/*--------------------------------------------------------*/
/*HOVER BOXES*/
.wrapper-element {
	max-width: 1400px !important;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 0px;
}

.parent-div {
	width: 420px;
	margin: 0px auto;
	height: 320px;
	overflow: hidden;
	position: relative;
	float: left;
	cursor: pointer;
	margin: 15px;
}

.child-div {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	padding: 15px;
}

.a-text-element {
	font-size: 24px !important;
	font-weight: 400;
	color: white !important;
	text-decoration: none;
	font-family: "Roboto", sans-serif !important;
	text-align: center !important;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin-bottom: 0;
	cursor: pointer;
	text-decoration: none;
}

.border-wrapper-div {
	position: relative;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	width: 100% !important;
	height: 100% !important;
	margin: 0 auto !important;
	padding: 15px;
}

.parent-div:hover .child-div, .parent-div:focus .child-div {
	-ms-transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-o-transform: scale(1.05);
	transform: scale(1.05);
}

.register-cta:hover {
	background-color: white;
	border-bottom: none;
}

.child-div {
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	padding: 25px !important;
}

.border-wrapper-div {
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	width: 100% !important;
	height: 100% !important;
	margin: 0 auto !important;
}

.parent-wrapper:hover .child-div > .border-wrapper-div {
	/* width: 70%!important; */
	/* height: 70%!important; */
	width: 90% !important;
	height: 90% !important;
	margin-right: auto !important;
	margin-left: auto !important;
	margin-top: 12px !important;
}

#cherry-row,
#city-centre {
	background-size: 450px;
	background-position: bottom right !important;
	background-repeat: no-repeat;
	padding: 120px 0px;
}

#explore-outer-row,
#live-a-little {
	background-size: 450px;
	background-position: bottom left !important;
	background-repeat: no-repeat;
	padding: 120px 0px;
}

.thb-mobile-menu {
	font-family: 'Roboto', 'BlinkMacSystemFont', -apple-system, 'Roboto', 'Lucida Sans';
}

/*----- F2 VIRTUAL TOUR -----*/
#or-virtual-tour {
	text-align: center;
	padding-top: 40%;
	position: relative;
	background: url(/wp-content/uploads/2021/05/the-campus-f2-virtual-tour.jpg) center center/cover;
	background-position: center center;
}

#or-vt-container {
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin: 0px auto;
}

#or-vt-container h3 {
	font-size: 50px;
	font-weight: 500;
	margin-bottom: 30px;
	font-family: "Cera Stencil Light", sans-serif !important;
	color: white !important;
}

#or-vt-container a {
	outline-offset: 5px;
	color: white !important;
	background: rgba(78, 171, 159, 0.7);
	padding: 15px 30px;
	outline: #4ba39a solid 1px;
}

#or-vt-container a:hover {
	transform: scale(1.03);
	background: rgba(78, 171, 159, 0.9);
}

#or-vt-container a::after {
	border-color: white;
}

#or-homebutton {
	text-align: left;
	display: block;
	font-size: 14px;
	letter-spacing: .24px;
	text-transform: uppercase;
	padding: 14px 20px;
	border: none;
	box-shadow: 0 10px 8px -5px rgba(0, 0, 0, 0.5);
	background-color: #efefef;
}

/*--------------------------------------------------------*/
/*MEDIA QUERIES*/
@media only screen and (min-width: 1200px) {
	#testimonials-row h1 {
		font-size: 60px;
		line-height: 43px;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1199px) {
	#testimonials-row h1 {
		font-size: 45px;
	}
	#testinomials-middle-column {
		padding-top: 38px;
	}
}

@media only screen and (min-width: 450px) and (max-width: 768px) {
	#testinomials-middle-column {
		padding-top: 57px;
	}
}

@media only screen and (min-width: 768px) {
	/* .animated-boxes-row {
      margin: 90px auto !important;
  } */
	.contact-inner-row {
		max-width: 1400px !important;
	}
	#time-to-live-a-little {
		padding: 120px 0px;
	}
	img.floorplan {
		padding: 80px 0px;
	}
	#take-a-hike {
		max-width: 1400px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		margin: 120px auto !important;
	}
	.testimonials-show-mobile {
		display: none;
	}
	.page-id-560 #lines-map .wpb_column {
		margin-top: 0px;
	}
	.copyright-desktop {
		display: inline-block;
	}
	.copyright-mobile {
		display: none;
	}
	.copyright-text p {
		font-size: 15px;
		display: inline-block;
		font-weight: 300;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 768px) {
	#animated-boxes-text {
		margin: 60px 0px !important;
	}
	.social-img a img {
		max-width: 32px;
	}
	.social-img a {
		margin: 0px 3px;
	}
	a.terms {
		display: inline-block;
		padding-bottom: 0px;
		font-weight: 300;
		margin: 0px 5px 0px 0px;
	}
	.block-page-content {
		padding: 0px 0px;
		color: #122146;
	}
	.contact-inner-row {
		max-width: 112.5rem !important;
		padding: 0px 0px !important;
	}
	.copyright-desktop {
		display: none;
	}
	.copyright-mobile {
		margin-top: 30px;
		display: block;
	}
	a.terms {
		border-bottom: none;
		display: inline-block;
		margin: 10px 5px;
		width: auto;
	}
	.copyright-text p {
		font-size: 15px;
		font-weight: 300;
	}
	.block-page-content h3 {
		font-size: 45px;
	}
	.floorplan {
		float: none;
		margin: 30px auto !important;
		padding: 0px 0px;
		text-align: center;
	}
	.inner-building-row {
		margin: 0px auto !important;
		padding: 0px 0px !important;
	}
	.header .logolink .logoimg {
		max-height: 60px !important;
		padding-top: 5px;
		padding-left: 15px;
	}
	#testimonials-row h1 {
		font-size: 35px !important;
	}
	.main-page-header {
		align-items: center !important;
		padding-top: 75% !important;
		padding-left: 100px !important;
		padding-right: 100px !important;
	}
	.building-bg-image {
		align-items: center !important;
		padding-left: 115px !important;
		padding-right: 115px !important;
	}
	.parent-div {
		width: 100%;
		display: block;
		max-width: 400px;
		margin: 20px auto !important;
		float: none;
	}
	#or-virtual-tour {
		padding-top: 80%;
	}
}

@media only screen and (min-width: 75.0625em) {
	.footer {
		padding: 40px 0;
	}
}

@media only screen and (min-width: 1025px) {
	.copyright-text {
		padding-top: 119px;
		text-align: right;
	}
	#time-to-live-a-little-child1, #time-to-live-a-little-child2 {
		padding-left: 0px;
		padding-right: 0px;
	}
	.time-to-live-a-little-child1 {
		padding-left: 0px;
		padding-right: 0px;
	}
	.inner-col {
		transition-timing-function: ease-in-out;
		transition: all 0.5s;
	}
	.inner-col:hover #wrapper1 {
		margin-top: 30% !important;
	}
	#col1:hover {
		padding-top: 152px;
		padding-bottom: 173px;
		max-height: 268px;
		margin-right: 20px;
		margin-left: 20px;
		margin-top: 15px;
		color: #00a69d !important;
	}
	#col2:hover {
		padding-top: 152px;
		padding-bottom: 173px;
		max-height: 268px;
		margin-right: 20px;
		margin-left: 20px;
		margin-top: 15px;
		color: #f9c3b5 !important;
	}
	#the-people-row1-txt-block {
		padding-right: 100px;
		padding-left: 100px;
	}
	.adjustable-text-block .time-to-live-a-little-child1 {
		margin-top: auto;
		margin-bottom: auto;
	}
	.adjustable-text-block img {
		max-width: 85% !important;
	}
}

.building-header-wrapper {
	padding: 25vh 0px 10vh;
}

@media only screen and (min-width: 1024px) {
	ul {
		float: left;
	}
	#ul1 {
		border-right: solid 1px #00a198;
	}
	#ul2 li {
		padding-left: 25px !important;
	}
	.footer-icon {
		margin: 8px;
	}
	#mobile-header-links {
		display: none;
	}
	.outer-logo {
		margin-left: 100px !important;
	}
	.logo-holder {
		float: left;
	}
	.the-story-header-wrapper {
		height: 300px;
	}
	.the-story-header-wrapper h1, .building-header-wrapper h1 {
		font-weight: 600;
	}
	.vc_custom_1536937258480 {
		background-position: top;
	}
	.header .logolink {
		padding-top: 15px;
		padding-left: 15px;
	}
}

@media only screen and (min-width: 1194px) {
	#ul2 li {
		padding-left: 25px !important;
	}
}

@media only screen and (max-width: 767px) {
	.block-page-content-white h3 {
		font-size: 40px !important;
	}
	.a-text-element {
		font-size: 19px !important;
	}
	.testimonial-logo {
		margin-top: 0px;
		height: auto;
	}
	#cherry-row, #explore-outer-row {
		padding: 50px 0px;
		background-size: 300px;
	}
	.block-page-content {
		margin-bottom: 50px !important;
	}
	.same-line {
		display: none;
	}
	#desktop-logo-holder {
		display: none;
	}
	.header .logolink .logoimg {
		max-height: 90px;
	}
	#mobile-header-links {
		margin-right: 10px;
		margin-left: -10px;
	}
	.footer .widget ul li a {
		display: inline-flex;
		align-items: center;
		width: auto;
		margin: 0px auto;
	}
	.image-bottom-icons {
		margin-top: -40px !important;
		margin-bottom: -40px !important;
	}
	#time-to-live-a-little {
		margin-top: 45px !important;
		padding-bottom: 20px;
	}
	#take-a-hike {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.page-id-560 #take-a-hike {
		padding-top: 0px !important;
		margin-top: 50px !important;
		padding: 0px;
		margin-bottom: 50px !important;
	}
	#city-map-frame {
		margin-top: 0px !important;
	}
	.block-img-mobile-adjust {
		padding-top: 50px;
		margin: 0px auto 50px auto;
	}
	.testimonials-hide-on-mobile {
		display: block;
	}
	#hover-boxes-row {
		display: none;
	}
	.the-story-header-wrapper hr, .header-txt-block-column hr {
		margin-top: 50px;
		margin-bottom: 50px !important;
	}
	#navbar {
		padding-left: 0px;
		padding-right: 0px;
	}
	.header .logolink .logoimg {
		max-height: 51px !important;
	}
	.mobile-toggle {
		padding: 0 !important;
	}
	.mobile-toggle > div {
		height: 16px;
	}
}

@media only screen and (max-width: 1023px) {
	#dekstop-header-links {
		display: none;
	}
	#mobile-header-links {
		font-family: FontAwesome !important;
		height: 40px !important;
	}
	#mobile-header-links i {
		font-style: normal;
		font-size: 22px;
	}
	#mobile-header-links a {
		height: 40px !important;
	}
	#fp-nav {
		display: none;
	}
	.header {
		background: rgba(255, 255, 255, 0.2);
		padding: 10px 0;
	}
	.header:before {
		background: rgba(255, 255, 255, 0.2);
	}
	.scroll-bottom {
		height: 70px;
	}
}

@media only screen and (max-width: 1024px) {
	#footer {
		padding-top: 40px;
		padding-bottom: 30px;
	}
	#menu-navigation li#menu-item-3061,
	#menu-navigation li#menu-item-2897,
	#menu-navigation li#menu-item-2896 {
		display: inline !important;
	}
}

@media only screen and (min-width: 1023px) {
	#image-bottom-icons1, #image-bottom-icons2, #image-bottom-icons3 {
		margin-top: -130px;
	}
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
	#the-story-row4 {
		margin-top: -100px !important;
		margin-bottom: -100px !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	#the-story-row4 .wpb_content_element {
		margin-bottom: 0px !important;
	}
	.mobile-toggle {
		line-height: 19px !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	/*.animated-boxes-row{*/
	/*    margin-top: 20px!important;*/
	/*}*/
}

@media only screen and (min-width: 1025px) {
	/*.animated-boxes-row{*/
	/*    margin-top: 20px!important;*/
	/*}*/
	#animated-boxes-row {
		padding: 0 55px !important;
	}
	#animated-boxes-text {
		max-width: 1400px;
		margin: 120px auto !important;
		padding: 0 0px !important;
	}
	.the-story-header-wrapper {
		padding-left: 0px;
		max-width: 1400px;
		margin: 0px auto;
	}
	.building-header-wrapper {
		padding-left: 0px;
	}
	#navbar {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.persian-green.bt-solid {
	margin-right: 45px !important;
	margin-bottom: 0 !important;
}

.persian-green.bt-solid button,
.persian-green.bt-solid a {
	background-color: #00A198 !important;
	color: #FFF !important;
}

.persian-green.bt-bordered {
	margin-bottom: 0 !important;
}

.persian-green.bt-bordered button,
.persian-green.bt-bordered a {
	background-color: transparent !important;
	border: 1px solid #00A198 !important;
	color: #00A198 !important;
}

.persian-green:hover {
	opacity: 0.7;
}

@media only screen and (max-width: 450px) {
	#the-people-row3-image, #the-people-row5-image {
		height: 600px !important;
	}
	#the-people-row4-video {
		height: 700px !important;
	}
	.title-wording {
		font-size: 50px !important;
		letter-spacing: normal;
		line-height: 65px;
	}
	.parent-div {
		width: 280px;
		height: 215px;
		display: block;
		max-width: 400px;
		margin: 20px auto !important;
		float: none;
	}
	.persian-green.bt-solid,
	.persian-green.bt-bordered {
		width: 100%;
		margin: 0 auto 15px !important;
		margin-top: 0 !important;
		margin-right: auto !important;
		margin-bottom: 15px !important;
		margin-left: auto !important;
		display: block !important;
		text-align: center;
	}
	.persian-green.bt-solid a,
	.persian-green.bt-bordered a {
		width: 100%;
	}
	.block-page-content h3 {
		font-size: 37px;
	}
}

@media only screen and (max-width: 640px) {
	.cursor {
		display: none !important;
	}
	.building-bg-image,
	.main-page-header {
		padding-left: 35px !important;
		padding-right: 35px !important;
	}
	.row-text-mobile-padding {
		padding-left: 25px;
		padding-right: 25px;
	}
	#background-device-bl {
		padding-top: 30px;
	}
	#the-people-row2 {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.time-to-live-a-little-child1 {
		padding-left: 40px;
		padding-right: 40px;
	}
	#time-to-live-a-little-child2 {
		padding-left: 40px !important;
		padding-right: 40px !important;
	}
	#background-device {
		padding-top: 50px;
		padding-bottom: 20px;
	}
	#the-people-header-img .wpb_content_element {
		margin-bottom: 0px !important;
	}
	.footer-list {
		text-align: center;
		line-height: 35px;
	}
	#footer {
		text-align: center;
		padding-bottom: 10px;
		padding-top: 95px;
	}
	#footer .textwidget {
		padding-bottom: 33px;
	}
	#footer #text-1 .textwidget {
		display: -webkit-box;
		display: flex;
	}
	#footer .textwidget ul {
		text-align: left;
		padding: 0 4%;
		-webkit-box-flex: 1;
	}
	#footer .textwidget ul li {
		display: inline-block;
		text-align: left;
	}
	#footer .textwidget ul li a {
		display: block;
		white-space: normal;
	}
	#footer .copyright-text p {
		font-size: 12px;
	}
	#mobile-menu.style2 .mobile-menu-bottom {
		padding: 0px 0 0px;
	}
	.social-img {
		margin-bottom: -30px;
	}
	#mobile-menu-logo {
		max-width: 125px;
		padding-top: 17px;
	}
	.current-menu-item a {
		background-color: rgba(0, 159, 152, 0.6);
		color: white;
		width: 70vw;
	}
	.thb-mobile-menu .next {
		position: absolute;
		right: 0%;
		margin-right: -25px;
	}
	.thb-mobile-menu .current-menu-item .next {
		margin-right: 25px;
	}
	.thb-mobile-menu li {
		line-height: 2.5;
	}
	.thb-mobile-menu a {
		font-size: 18px;
	}
	.parent-div {
		max-width: 90%;
	}
	#gallery .vc_images_carousel {
		height: 320px;
	}
	/* #gallery .vc_images_carousel img {
      width: auto !important;
      height: 100% !important;
  } */
	#download-brochure .btn {
		min-width: initial;
		width: 90%;
		padding: 10px 0;
		font-size: 18px;
	}
	.footer .widget ul li {
		line-height: 25px;
		padding: 10px 0;
	}
	#cookie-notice .cookie-notice-container {
		text-align: center;
	}
}

/* @media only screen and (max-width: 991px){
    .the-story-header-wrapper {
        padding: 0px 35px;
    }
} */
@media only screen and (max-width: 960px) {
	a.terms {
		color: #fff;
		text-decoration: none;
		border-bottom: 1px solid #00a199;
		padding-bottom: 0px;
		margin: 0px 5px 0px 0px;
	}
	.parent-div {
		width: 100%;
		display: block;
		max-width: 400px;
		margin: 20px auto !important;
		float: none;
	}
}

@media only screen and (min-width: 641px) {
	#background-device .background-device-image {
		margin-bottom: 100px;
		margin-top: 100px;
	}
	#the-people-row2 {
		background-position: bottom;
		padding-top: 120px;
		padding-bottom: 120px;
	}
	#line-breaker {
		display: none;
	}
	.current-menu-item a {
		color: #122146;
	}
	.thb-mobile-menu li {
		line-height: 1.8;
	}
}

@media only screen and (min-width: 641px) and (max-width: 1199px) {
	div#footer-block-3 {
		position: relative;
	}
	#footer-block-3 div#text-4 {
		position: absolute;
		text-align: right;
		bottom: 0px;
	}
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
	#background-device {
		padding-top: 50px;
		padding-bottom: 30px;
	}
	#background-device-bl {
		padding-top: 50px;
		padding-bottom: 30px;
	}
}

@media only screen and (min-width: 769px) {
	#background-device-bl {
		padding-top: 100px;
		padding-bottom: 75px;
	}
	.more-character {
		max-width: 1400px !important;
		margin: 0px auto 0px auto !important;
		padding: 0px 35px;
	}
}

@media only screen and (min-height: 800px) and (max-height: 800px) {
	#ifrm {
		height: 800px !important;
	}
}

@media only screen and (min-height: 900px) and (max-height: 900px) {
	#ifrm {
		height: 899px !important;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1200px) {
	#ifrm {
		height: 803px !important;
	}
}

@media only screen and (min-width: 1366px) and (max-width: 1366px) {
	#ifrm {
		height: 857px !important;
	}
}

@media only screen and (min-height: 1024px) and (max-height: 1024px) {
	#ifrm {
		height: 803px !important;
	}
}

@media only screen and (min-height: 1050px) and (max-height: 1050px) {
	#ifrm {
		height: 1055px !important;
	}
}

@media only screen and (min-width: 1600px) and (max-width: 1600px) {
	#ifrm {
		height: 1017px !important;
	}
}

@media only screen and (min-width: 1920px) and (max-width: 1920px) {
	#ifrm {
		height: 1206px !important;
	}
}

@media only screen and (min-height: 1536px) and (max-height: 1536px) {
	#ifrm {
		height: 1286px !important;
	}
}

@media screen and (min-width: 1200px) {
	/*.parent-div {
      height: 400px !important;
  }
      */
}

@media screen and (min-width: 1500px) {
	/*.parent-div {
      height: 450px !important;
  }
      */
}

/* FUNNY BOXES AT THE BOTTOM */
.bg-blockf {
	background-image: url(/wp-content/uploads/2022/09/f2-f3-hero-1.jpg);
}

.bg-f2 {
	background-image: url(/wp-content/uploads/2019/05/104_Entrance_F2-1-640x453.jpg);
}

.bg-f3 {
	background-image: url(/wp-content/uploads/2019/05/F3@2x-640x449.jpg);
}

.bg-overview {
	background-image: url(/wp-content/uploads/2022/09/F1-F2-480x450.jpg);
}

.bg-location {
	background-image: url(/wp-content/uploads/2019/05/the-campus-cherrywood-transport-location-480x450.jpg);
}

.bg-lifestyle {
	background-image: url(/wp-content/uploads/2022/09/lifestyle-hero.jpg);
}

.bg-thebuildings {
	background-image: url(/wp-content/uploads/2022/10/office-occupiers-hero-480x450-1.jpg);
}

.bg-bld3 {
	background-image: url(/wp-content/uploads/2022/09/TheCampus-July-27-Edited.jpg);
}

.bg-bld4 {
	background-image: url(/wp-content/uploads/2022/10/the-campus-building-4.jpg);
}

.bg-bld8 {
	background-image: url(/wp-content/uploads/2022/10/the-campus-building-8-1.jpg);
}

.bg-bld10 {
	background-image: url(/wp-content/uploads/2019/05/building-10-intro.jpg);
}

.bg-thevision {
	background-image: url(/wp-content/uploads/2018/09/c2-r1-1_60-480x450.jpg);
}

.bg-theportal {
	background-image: url(/wp-content/uploads/2018/11/Campus-1215_60-480x450.jpg);
}

.bg-contact {
	background-image: url(/wp-content/uploads/2018/11/Contact-Us-Banner-480x450.jpg);
}

.bg-blue {
	background-color: rgba(89, 142, 199, 0.7);
}

.bg-yellow {
	background-color: rgba(255, 231, 154, 0.7);
}

.bg-pink {
	background-color: rgba(250, 188, 173, 0.7);
}

.bg-green {
	background-color: rgba(0, 161, 152, 0.7);
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

@media only screen and (min-width: 1025px) {
	.parent-div {
		min-height: 340px;
		width: 100%;
		overflow: hidden;
		position: relative;
		float: none;
		height: 320px !important;
		display: block;
		cursor: pointer;
		margin: 0px auto !important;
	}
}

/* indivudual Media Queries for the funny boxes */
@media screen and (max-width: 1024px) {
	.parent-div {
		width: 100%;
		height: 217px;
		display: block;
		max-width: 300px;
		margin: 20px auto !important;
		float: none;
	}
	.viewport-height {
		height: auto !important;
	}
}

@media screen and (max-width: 768px) {
	.a-text-element {
		font-size: 19px !important;
		height: 60px;
	}
	.wrapper-element {
		padding: 30px 0px;
	}
}

/* individual stylyn on funny boxes for this page */
.green-bg {
	padding-top: 90px !important;
	padding-bottom: 90px !important;
}

@media only screen and (max-width: 767px) {
	.green-bg {
		padding-top: 50px !important;
		padding-bottom: 50px !important;
	}
	.animated-boxes-row.wrapper-element {
		padding: 30px 0px !important;
	}
}

@media only screen and (max-width: 640px) {
	.parent-div {
		max-width: 90%;
	}
	#footer #footer-block-1 .footer-list br {
		display: none;
	}
	#footer #footer-block-2 h4 {
		margin-bottom: 0;
	}
	#footer #footer-block-3 .copyright-mobile {
		margin-top: 0;
	}
}

@media only screen and (min-width: 640px) {
	#footer #footer-block-2 {
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}
	#footer #footer-block-2 h4 {
		margin-bottom: 0;
	}
	#footer #footer-block-3 {
		position: relative;
	}
	#footer #footer-block-3 #text-4 {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	#footer #footer-block-3 .copyright-text {
		padding-top: 0 !important;
	}
}

/* Full Page JS */
#fp-nav ul li a.active {
	border-color: #fff;
}

#fp-nav ul li a {
	border: 2px solid #fff;
}

iframe {
	max-height: 70vh !important;
	display: block;
	margin: auto;
}

/* Team Table Styles */
#team tbody {
	background-color: transparent !important;
	border: none !important;
}

#team tr {
	background-color: transparent !important;
	padding: 10px;
}

#team td {
	padding: 9px !important;
	border-bottom: 1px solid rgba(0, 161, 152, 0.7) !important;
	text-align: right;
	color: #FFF;
}

/*************************************
*** START LIFE SCIENCE PAGE STYLES ***
**************************************/
body.page-template-lifesciences-page-template .logo-dark {
	display: none;
}

body.page-template-lifesciences-page-template .logo-light {
	display: block !important;
}

#lifesciences {
	/*  height: 100vh;
      overflow:scroll;
      scroll-snap-type: mandatory;
      scroll-snap-points-y: repeat(100vh);
      scroll-snap-type: y mandatory;
  */
}

#lifesciences h1,
#lifesciences h2 {
	text-transform: uppercase;
	font-weight: 900;
	color: #fff;
	font-size: 90px;
	line-height: .9em;
}

#lifesciences p {
	color: #fff;
	font-size: 20px;
}

#lifesciences .ls-divider {
	opacity: 0;
	height: 3px;
	width: 130px;
	margin: 40px 0px 40px -100px;
	transition: all 1s ease;
}

#lifesciences .initial .ls-divider {
	transition: all 1s ease;
	margin: 40px 0px 40px 2px;
	-webkit-animation: fadeIn-fromLeft 750ms 1500ms both;
	animation: fadeIn-fromLeft 750ms 1500ms both;
}

#lifesciences .ls-divider.white {
	background-color: #fff;
}

#lifesciences .ls-divider.black {
	background-color: #000;
}

#lifesciences h1,
#lifesciences h2,
#lifesciences p {
	transition: all 1s ease;
	-webkit-animation: fadeOut 750ms 500ms both;
	animation: fadeOut 750ms 500ms both;
}

#lifesciences .initial h1 {
	-webkit-animation: fadeIn 750ms 1250ms both;
	animation: fadeIn 750ms 1250ms both;
	transition: all 1s ease;
}

#lifesciences .initial p {
	-webkit-animation: fadeIn 750ms 1500ms both;
	animation: fadeIn 750ms 1500ms both;
	transition: all 1s ease;
}

#lifesciences .visible .ls-divider {
	opacity: 1;
	margin: 40px 0px 40px 2px;
	transition: all 1s ease;
}

#lifesciences .visible h1,
#lifesciences .visible h2 {
	-webkit-animation: fadeIn 750ms 500ms both;
	animation: fadeIn 750ms 500ms both;
	transition: all 1s ease;
}

#lifesciences .visible p,
#lifesciences .visible .submit-wrapper,
#lifesciences #hero a.ls-scroll {
	-webkit-animation: fadeIn 750ms 750ms both;
	animation: fadeIn 750ms 750ms both;
	transition: all 1s ease;
}

#lifesciences .ls-section {
	height: 100vh;
	position: relative;
	overflow: hidden;
	background-size: cover;
	/*scroll-snap-align: start;*/
}

#lifesciences .ls-section .content-wrap {
	position: absolute;
}

/*
#lifesciences #hero{
    background-image: url(/wp-content/uploads/2021/05/life-sciences-dublin.jpg);
}
#lifesciences #section-1{
    background-image: url(/wp-content/uploads/2021/05/campus-cherrywood-lifesciences.jpg);
}
#lifesciences #section-2{
    background-image: url(/wp-content/uploads/2021/05/lifesciences-building.jpg);
}
#lifesciences #section-3{
    background-image: url(/wp-content/uploads/2021/05/lifesciences-campus.jpg);
}
*/
#lifesciences #hero .content-wrap {
	width: 40%;
	margin: 0 auto;
	left: 50%;
	transform: translateX(-50%);
	bottom: 10%;
}

#lifesciences #hero h1 {
	margin-left: -100px;
}

#lifesciences #hero a.ls-scroll {
	text-align: center;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

#lifesciences #hero .ls-scroll span {
	color: white;
	font-size: 15px;
}

#lifesciences #hero .ls-scroll img {
	margin-top: 0;
	transition: all .3s ease;
	max-width: 25px;
}

#lifesciences #hero .ls-scroll:hover img {
	margin-top: 10px;
}

#lifesciences #section-1 .ls-shape img {
	opacity: 0;
	position: absolute;
	height: 146vh;
	left: 40%;
	-webkit-animation: fadeOut-fromRight 750ms 0ms both;
	animation: fadeOut-fromRight 750ms 0ms both;
	transition: all 1s ease;
}

#lifesciences #section-1.visible .ls-shape img {
	opacity: 1;
	-webkit-animation: fadeIn-fromRight 750ms 0ms both;
	animation: fadeIn-fromRight 750ms 0ms both;
	animation-iteration-count: 1;
}

#lifesciences #section-1 .content-wrap {
	width: 45%;
	left: 50%;
	top: 5%;
	margin-left: -.5em;
}

#lifesciences #section-1 h2 {
	margin-left: -10vw;
}

#lifesciences #section-2 .ls-shape img {
	opacity: 0;
	position: absolute;
	height: 110vh;
	top: -5vh;
	-webkit-animation: fadeOut-fromLeft 750ms 0ms both;
	animation: fadeOut-fromLeft 750ms 0ms both;
	transition: all 1s ease;
}

#lifesciences #section-2.visible .ls-shape img {
	opacity: 1;
	-webkit-animation: fadeIn-fromLeft 750ms 0ms both;
	animation: fadeIn-fromLeft 750ms 0ms both;
	animation-iteration-count: 1;
}

#lifesciences #section-2 .content-wrap {
	width: 30%;
	left: 8%;
	top: 8%;
}

#lifesciences #section-2 h2 {
	margin-left: -5vw;
}

#lifesciences #section-3 .ls-shape img {
	height: 101vh;
	width: 101vw;
	object-fit: cover;
	-webkit-animation: fadeOut-fromRight 750ms 0ms both;
	animation: fadeOut-fromRight 750ms 0ms both;
	transition: all 1s ease;
}

#lifesciences #section-3.visible .ls-shape img {
	opacity: 1;
	-webkit-animation: fadeIn-fromRight 750ms 0ms both;
	animation: fadeIn-fromRight 750ms 0ms both;
	animation-iteration-count: 1;
}

#lifesciences #section-3 .content-wrap {
	width: 35%;
	left: 57%;
	top: 15%;
}

#lifesciences #section-3 h2 {
	margin-left: -13vw;
}

#lifesciences #contact {
	background-color: #f18d77;
	height: auto;
}

#lifesciences #contact .ls-shape {
	background-image: url("/wp-content/uploads/2021/06/shape4.svg");
	background-size: cover;
	background-position: top right;
	width: 80%;
	padding: 4% 20% 4% 4%;
	display: flex;
	justify-content: space-between;
	opacity: 0;
	-webkit-animation: fadeOut-fromLeft 750ms 0ms both;
	animation: fadeOut-fromLeft 750ms 0ms both;
	transition: all 1s ease;
}

#lifesciences #contact.visible .ls-shape {
	opacity: 1;
	-webkit-animation: fadeIn-fromLeft 750ms 0ms both;
	animation: fadeIn-fromLeft 750ms 0ms both;
	animation-iteration-count: 1;
}

#lifesciences #contact .ls-shape > div {
	width: 45%;
}

#lifesciences #contact h2 {
	margin-left: auto;
	width: auto;
	display: table;
}

#lifesciences #contact .form-wrap {
	padding-top: 8%;
}

#lifesciences #contact form > p {
	margin-bottom: 0;
}

#lifesciences #contact input,
#lifesciences #contact select {
	border: 2px solid black;
	margin-bottom: 20px;
	padding: 15px;
	font-size: 20px;
	background: transparent;
	color: black;
}

#lifesciences #contact input[type="submit"] {
	height: auto;
	padding: 15px 25px;
}

#lifesciences #contact .submit-wrapper {
	display: flex;
}

#lifesciences #contact .submit-wrapper .Acceptance {
	padding-right: 10%;
	position: relative;
}

#lifesciences #contact .submit-wrapper .Acceptance input[type="checkbox"] {
	position: absolute;
	margin-top: 8px;
}

#lifesciences #contact .submit-wrapper .Acceptance .wpcf7-list-item-label {
	font-size: 20px;
	left: 25px;
	position: relative;
}

#lifesciences #more {
	height: auto;
	padding: 5% 0;
}

#lifesciences #more .content-wrap {
	display: flex;
	width: 60%;
	left: 20%;
	height: auto;
	position: relative;
}

#lifesciences #more .content-wrap a {
	flex-grow: 1;
}

#lifesciences #more article {
	background-image: url("/wp-content/uploads/2021/05/life-sciences-dublin-300x300.jpg");
	background-size: cover;
	padding-bottom: 75%;
	margin: 10px;
	text-align: center;
	position: relative;
	z-index: -1;
	opacity: 0;
	-webkit-animation: fadeOut 750ms 250ms both;
	animation: fadeOut 750ms 250ms both;
	transition: all .5s ease;
}

#lifesciences #more a:hover article {
	transform: scale(1.05);
}

#lifesciences #more article span {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 100%;
	transform: translateY(-50%);
	z-index: 2;
	font-size: 20px;
	color: white;
}

#lifesciences #more article span::before {
	content: '';
	position: absolute;
	width: 85%;
	height: 85%;
	top: -42%;
	margin-left: 7.5%;
	display: block;
	opacity: .6;
	z-index: -1;
}

#lifesciences #more #ls-overview article {
	background-image: url("/wp-content/uploads/2022/09/F1-F2-IMG_0817-Edited.jpg");
}

#lifesciences #more.visible #ls-overview article {
	-webkit-animation: fadeIn 750ms 0ms both;
	animation: fadeIn 750ms 0ms both;
	transition: all 1s ease;
}

#lifesciences #more #ls-overview article span::before {
	background: #688eb9;
}

#lifesciences #more #ls-occupiers article {
	background-image: url("/wp-content/uploads/2022/09/TheCampus-July-27-Edited.jpg");
}

#lifesciences #more.visible #ls-occupiers article {
	-webkit-animation: fadeIn 750ms 150ms both;
	animation: fadeIn 750ms 150ms both;
	transition: all 1s ease;
}

#lifesciences #more #ls-occupiers article span::before {
	background: #e0d6a0;
}

#lifesciences #more #ls-lifestyle article {
	background-image: url("/wp-content/uploads/2022/09/lifestyle.jpg");
}

#lifesciences #more.visible #ls-lifestyle article {
	-webkit-animation: fadeIn 750ms 300ms both;
	animation: fadeIn 750ms 300ms both;
	transition: all 1s ease;
}

#lifesciences #more #ls-lifestyle article span::before {
	background: #d8bfc6;
}

@media screen and (min-width: 1999px) {
	#lifesciences #section-1 .ls-shape img {
		height: 130vh;
	}
}

@media screen and (max-width: 1799px) {
	#lifesciences h1,
	#lifesciences h2 {
		font-size: 70px;
	}
	#lifesciences p,
	#lifesciences #more article span {
		font-size: 18px;
	}
}

@media screen and (max-width: 1599px) {
	#lifesciences h1,
	#lifesciences h2 {
		font-size: 60px;
	}
	#lifesciences #hero .content-wrap {
		width: 50%;
	}
	#lifesciences #section-1 .ls-shape img {
		height: 145vh;
		left: 40%;
	}
	#lifesciences #section-1 .content-wrap {
		width: 45%;
		left: 50%;
	}
	#lifesciences #section-2 .ls-shape img {
		height: 120vh;
		margin-top: -15vh;
	}
	#lifesciences #section-2 .content-wrap {
		width: 35%;
		left: 7%;
	}
	#lifesciences #section-2 h2 {
		margin-left: -4vw;
	}
	#lifesciences #section-3 .content-wrap {
		top: 8%;
	}
}

@media screen and (max-width: 1399px) {
	#lifesciences .ls-divider,
	#lifesciences .visible .ls-divider {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	#lifesciences p,
	#lifesciences input,
	#lifesciences select,
	#lifesciences .wpcf7-list-item-label,
	#lifesciences #more article span {
		font-size: 16px;
	}
	#lifesciences #section-1 p:last-child {
		text-align: right;
	}
	#lifesciences #contact .ls-shape {
		display: block;
	}
	#lifesciences #contact .ls-shape > div {
		width: 100%;
	}
	#lifesciences #contact .form-wrap {
		padding: 6%;
	}
	#lifesciences #more .content-wrap {
		width: 80%;
		left: 10%;
	}
}

@media screen and (max-width: 1199px) {
	#lifesciences .ls-section h2 {
		font-size: 50px;
	}
	#lifesciences p,
	#lifesciences input,
	#lifesciences select,
	#lifesciences .wpcf7-list-item-label,
	#lifesciences #more article span {
		font-size: 13px;
	}
	#lifesciences #hero .ls-scroll {
		bottom: -8.5vh;
	}
}

@media screen and (max-width: 1024px) {
	#lifesciences .ls-section {
		height: 120vh;
	}
	#lifesciences .ls-section h2 {
		font-size: 60px;
	}
	#lifesciences p,
	#lifesciences #more article span {
		font-size: 16px;
	}
	#lifesciences .ls-shape {
		transform: rotate(90deg);
	}
	#lifesciences #section-1 .ls-shape img {
		top: -60vw;
		left: unset;
		right: -65vw;
	}
	#lifesciences #section-1 .content-wrap {
		width: 80%;
		left: 12%;
		top: 38%;
	}
	#lifesciences #section-1 h2 {
		margin-left: 35vw;
		padding-bottom: 3vh;
	}
	#lifesciences #section-2 .ls-shape {
		transform: none;
	}
	#lifesciences #section-2.visible .ls-shape img {
		top: 46vh;
		width: 100vw;
		height: 70vh;
		object-fit: cover;
		left: 0;
	}
	#lifesciences #section-2 .content-wrap {
		width: 40%;
		top: 26%;
		left: 15%;
	}
	#lifesciences #section-2 h2 {
		margin-left: -10vw;
	}
	#lifesciences #section-3.visible .ls-shape img {
		position: absolute;
		top: -60vw;
		left: 65vh;
	}
	#lifesciences #section-3 .content-wrap {
		width: 80%;
		left: 10%;
		top: 52%;
	}
	#lifesciences #section-3 h2 {
		margin-left: 0;
	}
	#lifesciences #contact .ls-shape {
		width: 100%;
	}
	#lifesciences #contact .ls-shape .form-wrap,
	#lifesciences #contact .ls-shape .header-wrap {
		width: 80%;
	}
	#lifesciences #more .content-wrap {
		flex-wrap: wrap;
		justify-content: center;
	}
	#lifesciences #more .content-wrap a {
		width: 60%;
		flex-grow: 0;
	}
}

@media screen and (max-width: 767px) {
	#lifesciences p,
	#lifesciences input,
	#lifesciences select,
	#lifesciences .wpcf7-list-item-label,
	#lifesciences #more article span {
		font-size: 16px !important;
	}
	#lifesciences .ls-section {
		background-size: 75vh auto;
	}
	#lifesciences #hero {
		background-size: cover;
		background-position: -90vw 0;
		height: auto;
		padding-bottom: 150px;
		background-size: cover;
	}
	#lifesciences #section-1 p:last-child {
		text-align: left;
	}
	#lifesciences #contact .submit-wrapper .Acceptance .wpcf7-list-item-label {
		left: 42px;
	}
	#lifesciences #more .content-wrap {
		top: 0 !important;
		margin-bottom: 0 !important;
	}
	#lifesciences #more .content-wrap a {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	#lifesciences .ls-section {
		height: auto;
		overflow: visible;
		position: relative;
		background-repeat: no-repeat;
	}
	#lifesciences .ls-shape {
		display: none;
	}
	#lifesciences .ls-section .content-wrap {
		position: relative !important;
		width: 100% !important;
		height: auto !important;
		margin: 0 0 40vh 0 !important;
		padding: 20px !important;
		top: 40vh !important;
		left: 0 !important;
	}
	#lifesciences h2 {
		margin-left: 0 !important;
	}
	#lifesciences #hero .content-wrap {
		transform: none !important;
		top: 100px !important;
		margin: 0 !important;
	}
	#lifesciences #hero h1 {
		margin-left: 0;
	}
	#lifesciences #section-1 .content-wrap {
		background-color: #f28a76;
	}
	#lifesciences #section-2 .content-wrap {
		background-color: #00a89c;
	}
	#lifesciences #section-3 .content-wrap {
		background-color: #ee7945;
	}
	#lifesciences #contact .ls-shape {
		background-position: left center;
		padding: 20px;
	}
	#lifesciences #contact .ls-shape .header-wrap,
	#lifesciences #contact .ls-shape .form-wrap {
		width: 100%;
	}
	#lifesciences #contact h2 {
		text-align: center;
		width: 100%;
	}
	#lifesciences .submit-wrapper {
		display: block;
	}
	#lifesciences input[type="submit"] {
		margin-left: auto;
		display: block;
	}
}

/*************************************
**** END LIFE SCIENCE PAGE STYLES ****
**************************************/
/**
Google Maps in footer
 */
.map-section {
	margin-bottom: 1em;
}

.map-section #map {
	width: 100%;
	height: 250px;
}

@media screen and (max-width: 900px) {
	.map-section #map {
		height: 300px;
	}
}

@media screen and (max-width: 600px) {
	.map-section {
		margin-bottom: 2em;
	}
	.map-section #map {
		height: 250px;
	}
}

.life-sciences-news {
	background: #f1af93;
	padding: 4em 0 2em 0;
	height: auto !important;
}

.life-sciences-news .posts-heading {
	text-align: center;
	padding-bottom: 2.5em;
}

.life-sciences-news .feed {
	padding: 0 8em;
	max-width: 1650px;
	margin: 0 auto;
	position: relative;
}

.life-sciences-news .feed .slide iframe {
	width: 100%;
	height: 560px;
}

.life-sciences-news .feed .slide .card-wrapper {
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	height: 560px;
	overflow: hidden;
}

.life-sciences-news .feed .slide .card-wrapper .social-card {
	height: 100%;
	background-color: #fff;
	overflow: auto;
	padding: 24px 0 0 0;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-header {
	padding: 0 32px;
	display: flex;
	align-items: center;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-header .card-logo {
	width: 48px;
	margin-right: 8px;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-header .card-link {
	font-size: .95rem;
	font-weight: 600;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body p {
	padding: 0 32px;
	color: rgba(0, 0, 0, 0.9) !important;
	font-size: 0.85rem !important;
	line-height: 1.2rem;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body a {
	color: #0a66c2;
	font-weight: 600;
	transition: .4s;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body a:hover {
	color: #05ac9f;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body .article-info {
	background-color: #fafafa;
	padding: 16px 32px;
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body .article-info a {
	font-size: 1.2rem;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.9);
}

.life-sciences-news .feed .slide .card-wrapper .social-card .card-body .article-info span {
	font-size: .75rem;
	display: block;
	padding-top: 8px;
}

.life-sciences-news .feed .owl-dots {
	padding-top: 2em;
}

.life-sciences-news .feed .owl-dots .owl-dot.active {
	position: relative !important;
}

.life-sciences-news .feed .owl-dots .owl-dot.active span {
	background: #4aa59c;
	border: solid 2px #4aa59c;
}

.life-sciences-news .feed .owl-dots .owl-dot:hover span {
	border: solid 2px #4aa59c;
}

.life-sciences-news .feed .owl-dots .owl-dot span {
	background-color: white;
	border: solid 1px #fff;
	width: 13px;
	height: 13px;
}

.life-sciences-news .owl-nav {
	margin: 0;
}

.life-sciences-news .owl-nav .owl-prev, .life-sciences-news .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.life-sciences-news .owl-nav .owl-prev i, .life-sciences-news .owl-nav .owl-next i {
	color: #fff;
	font-size: 6em;
}

.life-sciences-news .owl-nav .owl-prev {
	left: 2em;
}

.life-sciences-news .owl-nav .owl-next {
	right: 2em;
}

@media screen and (max-width: 1300px) {
	.life-sciences-news .feed .slide iframe {
		height: 460px;
	}
}

@media screen and (max-width: 1100px) {
	.life-sciences-news .feed {
		padding: 0 4em;
	}
	.life-sciences-news .owl-nav .owl-prev {
		left: 0;
	}
	.life-sciences-news .owl-nav .owl-next {
		right: 0;
	}
}

@media screen and (max-width: 900px) {
	.life-sciences-news .owl-nav .owl-prev i, .life-sciences-news .owl-nav .owl-next i {
		font-size: 4em;
	}
}

@media screen and (max-width: 600px) {
	.life-sciences-news {
		padding: 0 0 2em 0;
	}
	.life-sciences-news .posts-heading {
		padding: 20px;
		text-align: left;
	}
	.life-sciences-news .feed {
		padding: 0 2em;
	}
	.life-sciences-news .owl-nav .owl-prev i, .life-sciences-news .owl-nav .owl-next i {
		font-size: 3em;
	}
	.life-sciences-news .owl-nav .owl-prev {
		left: 0;
	}
	.life-sciences-news .owl-nav .owl-next {
		right: 0;
	}
}

#mobile-menu {
	background-image: url("https://thecampus.ie/wp-content/uploads/2022/10/TheCampus.jpg") !important;
}

body:not(.page-template-lifesciences-page-template) #navbar .logo-dark {
	display: none;
}

body:not(.page-template-lifesciences-page-template) #navbar .logo-light {
	display: block;
}

body:not(.page-template-lifesciences-page-template) #navbar.scrolling-up .logo-dark {
	display: block;
}

body:not(.page-template-lifesciences-page-template) #navbar.scrolling-up .logo-light {
	display: none;
}

body.page-template-default #navbar.scrolling-up .logo-dark {
	display: none !important;
}

body.page-template-default #navbar.scrolling-up .logo-light {
	display: block !important;
}

#linkedin-link a svg {
	max-width: 15px;
}

.blog-container {
	padding-bottom: 4rem;
}

.blog-container .article-title {
	color: #4EA59C;
	margin-bottom: 2rem;
}
