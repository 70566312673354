.life-sciences-news {
  background:#f1af93;
  padding:4em 0 2em 0;
  height:auto !important;

  .posts-heading{
    text-align:center;
    padding-bottom:2.5em;
  }

  .feed {
    padding:0 8em;
    max-width:1650px;
    margin:0 auto;
    position:relative;

    .slide{
      iframe{
        width:100%;
        height:560px;
      }
      .card-wrapper{
        border-radius: 8px;
        border:1px solid rgba(0,0,0,.15);
        height:560px;
        overflow:hidden;

        .social-card{
          height:100%;
          background-color: #fff;
          overflow:auto;
          padding:24px 0 0 0;

          .card-header{
            padding:0 32px;
            display: flex;
            align-items: center;
            .card-logo{
              width:48px;
              margin-right:8px;
            }
            .card-link{
              font-size:.95rem;
              font-weight:600;
            }
          }

          .card-body{
            p{
              padding:0 32px;
              color:rgba(0,0,0,.9)!important;
              font-size:.85rem!important;
              line-height:1.2rem;
            }
            a{
              color:#0a66c2; //LinkedIn blue
              font-weight:600;
              transition:.4s;
              &:hover{
                color:#05ac9f;
              }
            }
            .article-info{
              background-color:#fafafa;
              padding:16px 32px;
              a{
                font-size: 1.2rem;
                font-weight: 600;
                color:rgba(0,0,0,.9);
              }
              span{
                font-size:.75rem;
                display:block;
                padding-top: 8px;
              }
            }
            
          }
        }

      }
      
    }

    .owl-dots{
      padding-top:2em;

      .owl-dot{
        &.active{
          position:relative !important;
          span{
            background:#4aa59c;
            border:solid 2px #4aa59c;
          }
        }

        &:hover{
          span{
            border:solid 2px #4aa59c;
          }
        }

        span{
          background-color:white;
          border:solid 1px #fff;
          width:13px;
          height:13px;
        }
      }
    }
  }

  .owl-nav{
    margin:0;

    .owl-prev, .owl-next{
      position:absolute;
      top:50%;
      transform:translateY(-50%);

      i{
        color:#fff;
        font-size:6em;
      }
    }

    .owl-prev{
      left:2em;
    }

    .owl-next{
      right:2em;
    }
  }
}

@media screen and (max-width:1300px){
  .life-sciences-news{
    .posts-heading{
      h4{}
    }
    .feed{
      .slide{
        iframe{
          height:460px;
        }
      }
      .owl-dots{
        .owl-dot{
          &.active{
            span{}
          }
          &:hover{
            span{}
          }
          span{}
        }
      }
    }
    .owl-nav{
      .owl-prev, .owl-next{
        i{}
      }
      .owl-prev{}
      .owl-next{}
    }
  }
}

@media screen and (max-width:1100px){
  .life-sciences-news{
    .posts-heading{
      h4{}
    }
    .feed{
      padding:0 4em;
      .slide{
        iframe{}
      }
      .owl-dots{
        .owl-dot{
          &.active{
            span{}
          }
          &:hover{
            span{}
          }
          span{}
        }
      }
    }
    .owl-nav{
      .owl-prev, .owl-next{
        i{}
      }
      .owl-prev{
        left:0;
      }
      .owl-next{
        right:0;
      }
    }
  }
}

@media screen and (max-width:900px){
  .life-sciences-news{
    .posts-heading{
      h4{}
    }
    .feed{
      .slide{
        iframe{}
      }
      .owl-dots{
        .owl-dot{
          &.active{
            span{}
          }
          &:hover{
            span{}
          }
          span{}
        }
      }
    }
    .owl-nav{
      .owl-prev, .owl-next{
        i{
          font-size:4em;
        }
      }
      .owl-prev{}
      .owl-next{}
    }
  }
}

@media screen and (max-width:600px) {
  .life-sciences-news{
    padding:0 0 2em 0;
    .posts-heading{
      padding:20px;
      text-align:left;
      h4{}
    }
    .feed{
      padding:0 2em;
      .slide{
        iframe{}
      }
      .owl-dots{
        .owl-dot{
          &.active{
            span{}
          }
          &:hover{
            span{}
          }
          span{}
        }
      }
    }
    .owl-nav{
      .owl-prev, .owl-next{
        i{
          font-size:3em;
        }
      }
      .owl-prev{
        left:0;
      }
      .owl-next{
        right:0;
      }
    }
  }
}